import { Theme } from '@mui/material';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  ICellValue,
  Notification,
  Panel,
  Tab,
  Tabs,
  useData,
  useTableData,
  useTheme,
} from 'react-ui-kit-exante';

import { DEFAULT_TABLE_PAGE_SIZE, Locales } from '../../constants/common';
import { SettingsContext } from '../../contexts/SettingsContext';
import { useRequestAborting } from '../../hooks/useRequestAborting';
import { ServerRequestStatuses } from '../../services/api/Api.consts';
import { ResponseWithStatus } from '../../services/api/Api.types';
import { TranslationRatio } from '../../services/shaper/templates/templates.types';
import { TranslationService } from '../../services/shaper/translations';
import { TranslationFullInfo } from '../../services/shaper/translations/translations.types';
import { capitalizeFirst } from '../../utils/capitalizeFirst';

import { TranslationPreview } from './TranslationPreview/TranslationPreview';
import {
  defaultActiveCell,
  TranslationsNotifications,
  translationsTab,
} from './Translations.consts';
import {
  StyledTranslationsActions,
  StyledTranslationsTable,
} from './Translations.styled';
import { hiddenColumns, columns } from './columns';

export const Translations: FC = () => {
  const theme: Theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);

  const { canSyncTranslations } = useContext(SettingsContext);

  const {
    cancelTokenRef,
    newCancelToken,
    abortingLoading,
    onSetAbortingLoading,
  } = useRequestAborting();
  const [selectedCell, setSelectedCell] = useState(defaultActiveCell);
  const getTranslations = useCallback(() => {
    return TranslationService.getTranslationsByRevision(
      translationsTab[selectedTab].type,
      newCancelToken(),
    );
  }, [selectedTab]);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getTranslations },
      onFailure: (e: Error) => Notification.error({ title: e.message }),
    }),
    [getTranslations],
  );

  const { data, isLoading, fetchData } =
    useTableData<TranslationFullInfo>(tableDataArgs);

  const displayedColumnKeys = columns
    .filter((column) => !hiddenColumns.includes(column.Header as string))
    .map((column) => column.accessor) as string[];

  const tableData = useMemo(
    () =>
      data?.temps?.map((i) => {
        return {
          ...i,
          trans_ratios: i.trans_ratios.reduce((acc, curr) => {
            return { ...acc, [curr.locale]: curr };
          }, {} as Record<Locales, TranslationRatio>),
        };
      }),
    [data?.temps],
  );

  useEffect(() => {
    onSetAbortingLoading(false);
  }, [data?.temps]);

  const getCellProps = useCallback(
    (cell: ICellValue<any>) => {
      const rowIdx = Number(cell?.row?.id || 0);
      const colHeader = cell?.column.Header as Locales;
      const cellColor =
        tableData?.[rowIdx]?.trans_ratios[colHeader]?.percents_color;
      const isSelectedCell =
        rowIdx === selectedCell.rowIdx && colHeader === selectedCell.colHeader;
      if (Object.values(Locales).includes(cell?.column.Header)) {
        return {
          style: {
            backgroundColor: cellColor,
            boxSizing: 'border-box',
            border: isSelectedCell
              ? `1px solid ${theme?.color?.typo?.action}`
              : `1px solid ${cellColor}`,
          },
        };
      }
      return {};
    },
    [tableData, selectedCell],
  );

  const handleCellClick = (cell: ICellValue<any>) => {
    const colHeader = cell?.column.Header;
    if (Object.values(Locales).includes(colHeader)) {
      const rowIdx = Number(cell?.row?.id || 0);
      setSelectedCell({
        rowIdx,
        colHeader,
        templateData: {
          locale: colHeader,
          event: cell?.row?.original?.event,
        },
      });
    }
  };

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (cancelTokenRef) {
      cancelTokenRef.current?.cancel();
      onSetAbortingLoading(true);
    }
    setSelectedTab(newValue);
  };

  const pushKeys = useCallback(async () => {
    const successMessage = `${TranslationsNotifications.Type} is ${TranslationsNotifications.SuccessPush}`;
    try {
      const response = await TranslationService.pushKeys();
      if (response.status === ServerRequestStatuses.Success) {
        fetchData();
        Notification.success({ title: successMessage });
      }
      return response;
    } catch (error: any) {
      Notification.error(error?.message);
    }
    return null;
  }, []);

  const pullKeys = useCallback(async () => {
    const successMessage = `${TranslationsNotifications.Type} is ${TranslationsNotifications.SuccessPull}`;
    try {
      const response = await TranslationService.pullNew();
      fetchData();
      Notification.success({ title: successMessage });

      return response;
    } catch (error: any) {
      Notification.error(error?.message);
    }
    return null;
  }, []);

  const pushKeysData = useData<ResponseWithStatus | null>({
    onFetch: pushKeys,
    loading: false,
  });

  const pullKeysData = useData<TranslationFullInfo | null>({
    onFetch: pullKeys,
    loading: false,
  });

  const onPush = () => {
    pushKeysData.fetchData();
  };

  const onPull = () => {
    setSelectedCell(defaultActiveCell);
    pullKeysData.fetchData();
  };

  const controlsIsDisabled =
    pushKeysData.isLoading ||
    pullKeysData.isLoading ||
    isLoading ||
    !canSyncTranslations;

  const tabs = translationsTab.map(({ type }) => (
    <Tab label={capitalizeFirst(type)} key={type} />
  ));

  useEffect(() => {
    setSelectedCell(defaultActiveCell);
  }, [selectedTab]);

  return (
    <Panel
      title="Change translations"
      action={
        <StyledTranslationsActions>
          <Button
            onClick={onPush}
            disabled={controlsIsDisabled}
            loading={pushKeysData.isLoading}
          >
            Push
          </Button>
          <Button
            onClick={onPull}
            disabled={controlsIsDisabled}
            loading={pullKeysData.isLoading}
          >
            Pull
          </Button>
        </StyledTranslationsActions>
      }
    >
      <Tabs value={selectedTab} onChange={handleChangeTab}>
        {tabs}
      </Tabs>

      <StyledTranslationsTable
        columns={columns}
        data={tableData || []}
        isLoading={isLoading || abortingLoading}
        showTableInfo
        tableId="notification-shaper-translations"
        hasPagination
        displayedColumnKeys={displayedColumnKeys}
        handleCellClick={handleCellClick}
        getCellProps={getCellProps}
        isFlexLayout
        pageSize={DEFAULT_TABLE_PAGE_SIZE}
      />
      {data && selectedCell.templateData && (
        <TranslationPreview
          translationData={data}
          templateData={selectedCell.templateData}
        />
      )}
    </Panel>
  );
};
