import { ChangeEvent, MouseEvent, ReactChild, useMemo, useState } from 'react';

import { useTranslator } from '../../../contexts/TranslationContext';
import { IconButton } from '../../Button/IconButton';
import { Checkbox } from '../../Inputs/Checkbox';
import { SearchInput } from '../../Inputs/SearchInput/SearchInput';
import { typedMemo } from '../helpers/typedMemo';

import {
  StyledButtonGroup,
  StyledColumnSelectList,
  StyledColumnSelectListItem,
  StyledColumnSelectPopover,
  StyledColumnSelectWrapper,
  StyledIconButton,
} from './ColumnSelect.styled';
import { TColumnSelectProps } from './ColumnSelect.types';

const POPOVER_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
} as const;

const POPOVER_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
} as const;

export const ColumnSelectComponent = <Data extends object>({
  columns,
  selected,
  onChange,
  onReset,
  translator: customTranslator,
}: TColumnSelectProps<Data>) => {
  const { t } = useTranslator(customTranslator);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue('');
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange(event.target.name, checked);
  };

  const open = Boolean(anchorEl);

  const columnsFiltered = useMemo(
    () =>
      columns.filter(
        (column) =>
          column.accessor &&
          !column.hideInMenu &&
          typeof column.Header === 'string' &&
          column.Header.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [columns, searchValue],
  );

  return (
    <StyledColumnSelectWrapper className="ColumnSelect">
      <IconButton
        iconName="ListIcon"
        iconColor="secondary"
        iconSize={24}
        onClick={handleOpen}
        className="IconButton"
        data-test-id="table__head--column-button-open"
      />
      <StyledColumnSelectPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        className="ColumnSelectPopover"
        data-test-id="table__head--column-popover"
      >
        <SearchInput
          onChange={setSearchValue}
          inputProps={{
            fullWidth: true,
            className: 'SearchInput',
            placeholder: t('ui__table__colselect__search_placeholder'),
          }}
        />
        <StyledColumnSelectList className="ColumnSelectList">
          {columnsFiltered.map(({ id, required, Header }) => {
            return (
              <StyledColumnSelectListItem
                key={id}
                className="ColumnSelectListItem"
                data-test-id="table__head--column-checkbox"
              >
                <Checkbox
                  name={String(id)}
                  label={Header as ReactChild}
                  checked={selected.includes(id as string)}
                  disabled={required}
                  onChange={handleChange}
                />
              </StyledColumnSelectListItem>
            );
          })}
        </StyledColumnSelectList>
        <StyledButtonGroup variant="outlined" className="ButtonGroup">
          <StyledIconButton
            applyStates={false}
            aria-label={t('ui__table__colselect__btn_show_all')}
            className="IconButton"
            data-test-id="table__head--column-button-show-all"
            iconColor="primary"
            iconName="EyeIcon"
            iconSize={16}
            onClick={() => onReset('all')}
          />
          <StyledIconButton
            applyStates={false}
            aria-label={t('ui__table__colselect__btn_hide_all')}
            className="IconButton"
            data-test-id="table__head--column-button-hide-all"
            iconColor="primary"
            iconName="EyeDeactiveIcon"
            iconSize={16}
            onClick={() => onReset('none')}
          />
          <StyledIconButton
            applyStates={false}
            aria-label={t('ui__table__colselect__btn_reset')}
            className="IconButton"
            data-test-id="table__head--column-button-default"
            iconColor="primary"
            iconName="RotateCcwIcon"
            iconSize={16}
            onClick={() => onReset('default')}
          />
        </StyledButtonGroup>
      </StyledColumnSelectPopover>
    </StyledColumnSelectWrapper>
  );
};

export const ColumnSelect = typedMemo(ColumnSelectComponent);
