import { uniqueId } from 'lodash';
import { memo, useMemo } from 'react';

import { ReactComponent as ApproveIcon } from '../../../assets/approve.svg';
import { ReactComponent as DeclineIcon } from '../../../assets/decline.svg';
import { BulkActionsComponentProps } from '../types';

import { Wrapper, SelectLines, Button } from './BulkActions.styled';

const BulkActionsComponent = <Data extends object>({
  totalLines,
  bulkActions,
  selectedRows,
}: BulkActionsComponentProps<Data>) => {
  const hasRows = selectedRows.length > 0;
  const actions = bulkActions?.actions;
  const onApprove = bulkActions?.onApprove;
  const onDecline = bulkActions?.onDecline;
  const approveText = bulkActions?.approveText;
  const declineText = bulkActions?.declineText;

  const values = useMemo(
    () => selectedRows.map((item) => item.values),
    [selectedRows],
  );

  const selectedLines = values.length || 0;

  const handleApproveClick = () => {
    if (hasRows) {
      onApprove?.(values);
    }
  };

  const handleDeclineClick = () => {
    if (hasRows) {
      onDecline?.(values);
    }
  };

  const approveDeclineActions = () =>
    onApprove && onDecline ? (
      <>
        <Button
          disabled={!hasRows}
          onClick={handleApproveClick}
          margin={approveText && '0 20px 0 -2px'}
          iconColor={hasRows ? 'action' : 'ghost'}
        >
          <ApproveIcon />
          {approveText && <span>{approveText}</span>}
        </Button>
        <Button
          disabled={!hasRows}
          onClick={handleDeclineClick}
          iconColor={hasRows ? 'radical' : 'ghost'}
        >
          <DeclineIcon />
          {declineText && <span>{declineText}</span>}
        </Button>
      </>
    ) : null;

  const dynamicActions = () =>
    actions?.map((action) => {
      return <span key={uniqueId()}>{action()}</span>;
    });

  return (
    <Wrapper>
      <SelectLines data-test-id="bulk-actions__selected-lines">{`Selected lines: ${selectedLines}/${totalLines}`}</SelectLines>
      {actions ? dynamicActions() : approveDeclineActions()}
    </Wrapper>
  );
};

export default memo(BulkActionsComponent) as typeof BulkActionsComponent;
