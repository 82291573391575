import { ForwardedRef, forwardRef, useCallback, useRef } from 'react';

import { IconButton } from '../../../Button/IconButton';

import { SwapRowsCellContainer } from './styled';
import type { Direction, SwapRowsProps } from './types';

const SwapRowsInner = <Data extends object>(
  {
    onSwapRows,
    row,
    rows,
    swapRowDown,
    swapRowUp,
    ...rest
  }: SwapRowsProps<Data>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const defaultRef = useRef<HTMLDivElement>(null);
  const resolvedRef = ref || defaultRef;

  const updateTableData = (direction: Direction) => {
    const { index: rowIndex } = row;
    const tableData: Data[] = rows.map(
      (rowItem) =>
        ({
          ...rowItem.values,
        } as Data),
    );

    const swapRowIndex = rows.findIndex((rowItem) => {
      const { index } = rowItem;

      if (index === rowIndex) {
        return true;
      }

      return false;
    });

    if (direction === 'down' && swapRowIndex !== -1) {
      [tableData[swapRowIndex], tableData[swapRowIndex + 1]] = [
        tableData[swapRowIndex + 1],
        tableData[swapRowIndex],
      ];
    }

    if (direction === 'up' && swapRowIndex !== -1) {
      [tableData[swapRowIndex], tableData[swapRowIndex - 1]] = [
        tableData[swapRowIndex - 1],
        tableData[swapRowIndex],
      ];
    }

    if (onSwapRows) {
      onSwapRows(tableData);
    }
  };

  const swapRowDownDisabled = () => {
    const { index: currentRowIndex } = row;
    const lastRow = rows[rows.length - 1];
    const { index: lastRowIndex } = lastRow;

    return currentRowIndex === lastRowIndex;
  };

  const swapRowUpDisabled = () => {
    const { values: currentRowIndex } = row;
    const [firstRow] = rows;
    const { values: firstRowIndex } = firstRow;

    return currentRowIndex === firstRowIndex;
  };

  const handleSwapRowDown = useCallback(() => {
    updateTableData('down');
    swapRowDown?.('down', row, rows);
  }, [swapRowDown, row, rows]);

  const handleSwapRowUp = useCallback(() => {
    updateTableData('up');
    swapRowUp?.('up', row, rows);
  }, [swapRowUp, row, rows]);

  return (
    <SwapRowsCellContainer ref={resolvedRef} {...rest}>
      <IconButton
        disabled={swapRowUpDisabled()}
        iconColor="secondary"
        iconName="SwapRowUpIcon"
        iconSize={10}
        onClick={handleSwapRowUp}
      />

      <IconButton
        disabled={swapRowDownDisabled()}
        iconColor="secondary"
        iconName="SwapRowDownIcon"
        iconSize={10}
        onClick={handleSwapRowDown}
      />
    </SwapRowsCellContainer>
  );
};
SwapRowsInner.displayName = 'SwapRows';

export const SwapRows = forwardRef(SwapRowsInner) as <Data extends object>(
  props: SwapRowsProps<Data> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof SwapRowsInner<Data>>;
