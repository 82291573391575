import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  DownloadIcon,
  Notification,
  TCellValue,
  Table,
  XLSIcon,
  useTableData,
} from 'react-ui-kit-exante';

import { FileInput } from 'components/FileInput';
import { PageContext } from 'contexts/PagesContext';
import { defaultFilterState } from 'pages/Events/filters/filters';
import {
  FiltersState,
  getAdditionalFilters,
} from 'pages/Templates/filters/filters';
import { EVENTS_PATH } from 'routes';
import { paramsSerializer } from 'services/api/Api.helpers';
import { getFiltersOptions } from 'services/shaper/templates/templates.service';

import { DEFAULT_TABLE_PAGE_SIZE, PAGES } from '../../constants/common';
import { SettingsContext } from '../../contexts/SettingsContext';
import { TemplatesService } from '../../services/shaper/templates';
import { TemplatesState } from '../../services/shaper/templates/templates.types';
import { calculateCountOfPages } from '../../utils';

import { StyledTemplatesActions } from './Templates.styled';
import { getColumns, hiddenColumns } from './columns';
import { EXPORTED_TEMPLATES_FILENAME } from './constants';
import { generateTemplateTextsFilename } from './helpers';

export const Templates: FC = () => {
  const navigate = useNavigate();
  const { brandings } = useContext(SettingsContext);
  const { pageFrom, setPageFrom, setFilterParams } = useContext(PageContext);
  const fileReaderInstance = useRef(new FileReader());
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isTemplateTextsLoading, setIsTemplateTextsLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (pageFrom !== PAGES.TEMPLATES) {
      setPageFrom(PAGES.TEMPLATES);
    }
  }, []);

  const [filterOptions, setFilterOptions] =
    useState<FiltersState>(defaultFilterState);

  const getTemplates = useCallback(
    ({ params, filtersParams }: any) =>
      TemplatesService.getTemplates(params, filtersParams),
    [],
  );

  useEffect(() => {
    const getOptions = async () => {
      try {
        const filters = await getFiltersOptions(brandings);
        setFilterOptions(filters);
      } catch (error: any) {
        Notification.error(error?.message);
      }
    };
    getOptions();
  }, []);

  const tableDataArgs = useMemo(
    () => ({
      data: {
        onFetch: getTemplates,
        onFailure: (e: Error) => Notification.error({ title: e.message }),
      },
    }),
    [getTemplates],
  );

  const {
    data,
    isLoading,
    setPage,
    page,
    limit,
    setLimit,
    setFilter,
    setSorting,
    removeFilter,
    resetFilters,
    filters,
  } = useTableData<TemplatesState>(tableDataArgs);

  const filterQueryString = window.location.search;

  useEffect(() => {
    setFilterParams(filterQueryString);
  }, [filters]);

  const total = data?.total || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useMemo(() => getColumns(), [setFilter, removeFilter]);

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filterOptions,
      }),
    [filterOptions, setFilter, removeFilter],
  );

  const displayedColumnKeys = columns
    .filter((column) => !hiddenColumns.includes(column.Header as string))
    .map((column) => column.accessor) as string[];

  const handleRowClick = useCallback(
    ({ event_id, name }: any) => {
      navigate(`${EVENTS_PATH}/${event_id}?templateId=${name}`);
    },
    [navigate],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
    }),
    [additionalFilters, resetFilters, filters],
  );

  const loadHandle = async () => {
    const { result } = fileReaderInstance.current;

    if (typeof result !== 'string' || result.trim() === '') {
      Notification.error({
        title: 'Incorrect file',
      });
      return;
    }

    const parsedJSON = JSON.parse(result);

    let responseImport;

    try {
      if (parsedJSON) {
        responseImport = await TemplatesService.importTemplates(parsedJSON);
      }

      if (responseImport) {
        const templates = responseImport.saved_templates.ids;
        const successMessage = `Templates ${templates} are successfully imported`;

        Notification.success({ title: successMessage });
      }
    } catch (error: any) {
      Notification.error({
        title: 'Error',
        description: (
          <span
            dangerouslySetInnerHTML={{
              __html: error?.message,
            }}
          />
        ),
      });
    }
  };

  const handleInputFile = useCallback(
    (event: any) => {
      fileReaderInstance.current = new FileReader();
      fileReaderInstance.current.onload = loadHandle;
      if (event.target.files[0]) {
        fileReaderInstance.current.readAsText(event.target.files[0]);
      }

      // reset the value in order to be able to download the same file
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    },
    [loadHandle],
  );

  const handleTextsExport = async () => {
    setIsTemplateTextsLoading(true);

    const filtersMapping: { [key: string]: string } = {
      search: 'search',
      name__icontains: 'name__icontains',
      event__name: 'event__name__in',
      tags__name: 'event__tags__name',
      templates__type: 'type',
      group__in: 'group__in',
    };
    const templateFilters = Object.keys(filters).reduce(
      (acc: { [key: string]: unknown }, current) => {
        let filterName = filtersMapping[current as keyof typeof filtersMapping];
        if (
          filterName === 'event__name' &&
          String(filters[current]).includes(',')
        ) {
          filterName = 'event__name__in';
        }
        acc[filterName] = filters[current];
        return acc;
      },
      {},
    );

    const params = paramsSerializer(templateFilters);

    let responseExport;

    try {
      responseExport = await TemplatesService.exportTemplatesTexts(params);

      if (responseExport) {
        const downloadUrl = URL.createObjectURL(responseExport);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = generateTemplateTextsFilename();

        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(downloadUrl);

        const successMessage = `Templates texts are successfully exported`;
        Notification.success({ title: successMessage });
        setIsTemplateTextsLoading(false);
      }
    } catch (error: any) {
      Notification.error({
        title: 'Error',
        description: (
          <span
            dangerouslySetInnerHTML={{
              __html: error?.message,
            }}
          />
        ),
      });
      setIsTemplateTextsLoading(false);
    }
  };

  const hasSelectedRows = selectedRows.length > 0;

  const handleSelectRow = useCallback(
    (value: TCellValue) => {
      setSelectedRows((prev) => {
        if (Array.isArray(value)) {
          return value.map((item) => item);
        }

        if (prev.includes(value)) {
          return prev.filter((row) => row !== value);
        }

        return [...prev, value];
      });
    },
    [setSelectedRows],
  );

  const selectedTemplatesNames: Record<string, unknown>[] = useMemo(
    () => selectedRows.map((item) => item.values.name),
    [selectedRows],
  );

  const handleTemplatesExport = async () => {
    if (hasSelectedRows) {
      let responseExport;

      try {
        if (selectedTemplatesNames.length) {
          responseExport = await TemplatesService.exportTemplates(
            selectedTemplatesNames,
          );
        }

        if (responseExport) {
          const downloadUrl = URL.createObjectURL(responseExport);

          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = EXPORTED_TEMPLATES_FILENAME;

          document.body.appendChild(link);

          link.click();
          link.remove();

          URL.revokeObjectURL(downloadUrl);

          const successMessage = `Templates ${selectedTemplatesNames} are successfully exported`;

          Notification.success({ title: successMessage });
        }
      } catch (error: any) {
        Notification.error({
          title: 'Error',
          description: (
            <span
              dangerouslySetInnerHTML={{
                __html: error?.message,
              }}
            />
          ),
        });
      }
    }
  };

  const templatesActions = () => (
    <StyledTemplatesActions>
      <Button
        onClick={handleTemplatesExport}
        data-test-id="notifications-module__button--export-templates"
        color="transparent"
        disabled={!hasSelectedRows}
        icon={<DownloadIcon size={24} />}
      >
        Export selected templates
      </Button>
      <FileInput
        accept=".json"
        onInputFile={handleInputFile}
        label="Import Templates"
      />
      <Button
        onClick={handleTextsExport}
        data-test-id="notifications-module__button--export-texts"
        disabled={isTemplateTextsLoading}
        color="transparent"
        icon={<XLSIcon size={24} />}
      >
        Export all templates texts
      </Button>
    </StyledTemplatesActions>
  );

  const actions = [templatesActions];

  return (
    <Table
      title="Templates"
      columns={columns}
      data={data?.result || []}
      isLoading={isLoading}
      tableId="notification-shaper-templates"
      hasFilters
      showTableInfo
      filteringProps={filteringProps}
      displayedColumnKeys={displayedColumnKeys}
      hasPagination
      manualSortBy
      defaultSortBy={[]}
      onSort={setSorting}
      isFlexLayout
      isPinnedHeader
      saveColumnOrder
      handleRowClick={handleRowClick}
      bulkActions={{
        setSelectedRows: handleSelectRow,
        selectedRows,
        actions,
      }}
      serverPaginationProps={{
        pageSize: limit,
        setPage,
        setPageSize: setLimit,
        pageIndex: page,
        total,
        pageCount,
      }}
      pageSize={DEFAULT_TABLE_PAGE_SIZE}
    />
  );
};
