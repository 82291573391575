import { Theme } from '@mui/material';
import { styled } from 'react-ui-kit-exante';

interface CounterProps {
  theme?: Theme;
  isActive: boolean;
}

export const Counter = styled('span')(({ theme, isActive }: CounterProps) => ({
  padding: '2px 8px',
  borderRadius: '32px',
  fontSize: '12px',
  lineHeight: '16px',
  border: `2px solid ${
    isActive ? theme?.color?.typo?.action : theme?.color?.typo?.primary
  }`,
}));
