import { AutocompleteRenderInputParams } from '@mui/material';
import { PopperProps } from '@mui/material/Popper';
import { intersectionWith, isObject, isArray } from 'lodash';
import { useCallback, useMemo } from 'react';

import SelectArrowIcon from '../../../Icons/components/ArrowDown';
import { DefaultThemeProvider } from '../../../theme';
import { Skeleton } from '../../Skeleton';
import { Input } from '../Input/Input';

import { AutoCompleteStyled, StyledPopper } from './Autocomplete.styled';
import { IAutocompleteProps } from './types';

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  isMultiple,
  options,
  onChange,
  dataTestId,
  value,
  placeholder,
  size = 'medium',
  renderInput: renderInputProp,
  controlProps,
  fullWidth,
  showSkeleton,
  floating = true,
  ...props
}: IAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const defineStyledPopper = useCallback(
    (popperProps: PopperProps) => <StyledPopper size={size} {...popperProps} />,
    [size],
  );

  const setValidValue = () => {
    const isValidValueWithOptions =
      isArray(value) &&
      isObject(options?.[0]) &&
      (value as Array<[]>)[0] &&
      !isObject((value as Array<[]>)[0]);

    if (isValidValueWithOptions) {
      const validValue: T[] =
        (value &&
          intersectionWith(options, value as [], (option, num) => {
            // it is necessary for compare string and number
            // eslint-disable-next-line eqeqeq
            return (option as any).value == num;
          })) ||
        [];

      return validValue as typeof value;
    }

    return value;
  };

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      if (renderInputProp) {
        return renderInputProp(params);
      }

      return (
        <Input
          placeholder={placeholder}
          {...controlProps}
          {...params}
          InputProps={params.InputProps}
        />
      );
    },
    [renderInputProp, placeholder, controlProps],
  );

  const sx = useMemo(
    () => ({
      width: fullWidth ? '100%' : 320,
    }),
    [fullWidth],
  );

  const chipProps = useMemo(() => ({ size }), [size]);

  return (
    <DefaultThemeProvider>
      {showSkeleton ? (
        <Skeleton
          width={fullWidth ? '100%' : 320}
          height={size === 'medium' ? 48 : 32}
        />
      ) : (
        <AutoCompleteStyled
          forcePopupIcon
          disablePortal
          floating={floating}
          multiple={isMultiple}
          options={options}
          sx={sx}
          value={setValidValue()}
          size={size}
          ChipProps={chipProps}
          popupIcon={<SelectArrowIcon />}
          renderInput={renderInput}
          onChange={onChange}
          PopperComponent={defineStyledPopper}
          data-test-id={dataTestId}
          {...props}
        />
      )}
    </DefaultThemeProvider>
  );
};
