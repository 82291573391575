import { ComponentType, forwardRef, ReactNode } from 'react';

import { blockNonNativeProps } from '../../helpers';
import { styled } from '../../theme';
import { NoData } from '../NoData';

import {
  IHeaderStyledProps,
  IHeadRowStyledProps,
  ITableStyledProps,
  ITextLabelStyledProps,
  Styles,
  TCellStyledProps,
  TRowStyledProps,
  TTableContentStyledProps,
  TTableNoDataStyledProps,
} from './types';

export const TableStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isPinnedHeader', 'showScrollbar']),
})(({ isPinnedHeader, showScrollbar }: ITableStyledProps) => {
  const styles: Styles = {
    overflowY: 'hidden',
  };

  if (showScrollbar) {
    styles.overflowX = 'auto';
  }

  if (isPinnedHeader) {
    styles.overflow = 'scroll';
  }

  return styles;
});

export const OverflowedMask = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    right: 0,
    width: '15px',
    pointerEvents: 'none',
    backgroundColor: theme?.color.table.bg.basic2,
    boxShadow: `0px 0px 15px 10px ${theme?.color.table.bg.basic2}`,
    clipPath: 'inset(0px 0px 0px -10px)',
    height: '100%',
  };
});

export const CellWrapperStyled = styled('div')(
  ({ hasSubColumns, theme }: { hasSubColumns?: boolean; theme: any }) => {
    const borderStyle = `1px solid ${theme?.color.dropdown.border}`;

    return {
      borderLeft: hasSubColumns ? borderStyle : '',
      borderRight: hasSubColumns ? borderStyle : '',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
    };
  },
);

export const CellWithTooltipWrapper = styled('div')(
  ({ hasSubColumns }: { hasSubColumns: boolean }) => ({
    display: 'inline-block',
    alignSelf: hasSubColumns ? 'flex-start' : 'flex-end',
  }),
);

const StyledCellComponent = styled('div', {
  shouldForwardProp: blockNonNativeProps([
    'depth',
    'displaysOfTreeLines',
    'isCanExpanded',
    'isExpandingTable',
    'isFirstInRow',
    'isHeadCell',
    'isStickyCell',
    'hasSort',
    'hasSubColumns',
    'hasBorders',
  ]),
  label: 'table-cell',
})<TCellStyledProps>(
  ({
    depth,
    displaysOfTreeLines,
    isCanExpanded,
    isExpandingTable,
    isFirstInRow,
    isHeadCell,
    isStickyCell,
    theme,
    hasSubColumns,
    hasBorders,
  }) => {
    const {
      displayTopTreeLine,
      displayBottomTreeLine,
      displayRoundedTopTreeLine,
    } = displaysOfTreeLines || {};

    const startLeftpadding = 24;
    const startLeftOffsetTreeLine = 31;
    const doubleOuterWidthOfExpanderIcon = 6 + 10 + 6;

    const getPadding = () => {
      if (isHeadCell && hasSubColumns) {
        return '0';
      }

      if (isHeadCell && hasBorders) {
        return `0 24px`;
      }

      return `8px 24px`;
    };

    let style: Styles = {
      padding: getPadding(),
      flexDirection: hasSubColumns ? 'column' : 'row',
      fontSize: theme?.size.text[isHeadCell ? 'sm' : 'md'],
      width: '100%',
      height: '100%',
      position: 'relative',
      borderLeft:
        hasBorders && hasSubColumns
          ? `1px solid ${theme?.color.dropdown.border}`
          : '',
    };

    if (isStickyCell) {
      style = {
        ...style,
        boxSizing: 'border-box',
        position: 'sticky',
        right: 0,
        backgroundColor: theme?.color.table.bg.basic2,
        boxShadow: `4px 0px 15px 14px ${theme?.color.table.bg.basic2}`,
        clipPath: 'inset(1px 0px 0px -15px)',
        padding: '8px',
        alignItems: 'flex-start',
      };
    }

    if (isExpandingTable && isFirstInRow) {
      const basePseudoElementsStyles = {
        position: 'absolute',
        content: '""',
        color: '#ebebeb',
        boxSizing: 'border-box',
      };

      if (isCanExpanded) {
        const styleDisplayTopTreeLine = {
          display: displayTopTreeLine ? 'block' : 'none',
        };
        const styleDisplayBottomTreeLine = {
          display: displayBottomTreeLine ? 'block' : 'none',
        };
        const styleTopTreeLine = displayRoundedTopTreeLine
          ? {
              left: `${
                startLeftOffsetTreeLine +
                doubleOuterWidthOfExpanderIcon * (depth - 1)
              }px`,
              width: '15px',
              height: '17px',
              borderLeft: '2px solid currentColor',
              borderBottom: '2px solid currentColor',
              borderBottomLeftRadius: '4px',
            }
          : {
              height: '8px',
              width: '2px',
              backgroundColor: 'currentcolor',
              left: `${
                startLeftOffsetTreeLine + doubleOuterWidthOfExpanderIcon * depth
              }px`,
            };

        style = {
          ...style,
          position: 'relative',
          paddingLeft: `${
            startLeftpadding + depth * doubleOuterWidthOfExpanderIcon
          }px`,

          '&::before': {
            ...basePseudoElementsStyles,
            ...styleDisplayTopTreeLine,
            ...styleTopTreeLine,
            top: 0,
          },
          '&::after': {
            ...basePseudoElementsStyles,
            ...styleDisplayBottomTreeLine,
            backgroundColor: 'currentcolor',
            height: '8px',
            width: '2px',
            bottom: 0,
            left: `${
              startLeftOffsetTreeLine + doubleOuterWidthOfExpanderIcon * depth
            }px`,
          },
        };
      }

      if (!isCanExpanded) {
        const styleDisplayBottomTreeLine = {
          display: displayBottomTreeLine ? 'block' : 'none',
        };

        style = {
          ...style,
          position: 'relative',
          paddingLeft: `${
            startLeftOffsetTreeLine +
            doubleOuterWidthOfExpanderIcon * (depth - 1) +
            40
          }px`,

          '&::before': {
            ...basePseudoElementsStyles,
            top: 0,
            left: `${
              startLeftOffsetTreeLine +
              doubleOuterWidthOfExpanderIcon * (depth - 1)
            }px`,
            width: '23px',
            height: '17px',
            borderLeft: '2px solid currentColor',
            borderBottom: '2px solid currentColor',
          },
          '&::after': {
            ...basePseudoElementsStyles,
            ...styleDisplayBottomTreeLine,
            bottom: 0,
            left: `${
              startLeftOffsetTreeLine +
              doubleOuterWidthOfExpanderIcon * (depth - 1)
            }px`,
            width: '2px',
            height: '15px',
            backgroundColor: 'currentColor',
          },
        };
      }
    }

    return style;
  },
);

export const CellStyled = forwardRef(function CellStyled(
  { options, ...props }: any,
  ref,
) {
  return <StyledCellComponent {...props} ref={ref} />;
});

export const ActionCellStyled = (props: TCellStyledProps) => {
  return (
    <CellStyled
      options={{ label: 'table__action-cell' }}
      isStickyCell
      {...props}
    />
  );
};

export const RowStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps([
    'isColoredRow',
    'isSelectedRow',
    'isClickable',
  ]),
})<TRowStyledProps>(({ theme, isColoredRow, isSelectedRow, isClickable }) => {
  const getCellStyles = (color: 'hover' | 'basic1') => ({
    backgroundColor: theme?.color.table.bg[color],
    boxShadow: `-2px 4px 6px 5px ${theme?.color.table.bg[color]}`,
    clipPath: 'inset(1px 0px 0px -15px)',
  });

  let styles: Styles = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 auto',
    minWidth: 55,
    flexGrow: 0,
    boxShadow: theme?.color.defaultBoxShadow,
    backgroundColor: theme?.color.table.bg.basic2,
    color: theme?.color.typo.primary,
    cursor: isClickable ? 'pointer' : 'default',

    '&:hover': {
      backgroundColor: theme?.color.table.bg.hover,
    },
    '&:hover > [class*=table__action-cell]': getCellStyles('hover'),
  };

  if (isColoredRow) {
    styles = {
      ...styles,
      [`&:nth-of-type(odd)`]: {
        backgroundColor: theme?.color.table.bg.basic1,
        '&:hover': {
          backgroundColor: theme?.color.table.bg.hover,
        },
      },
      '&:nth-of-type(odd) [class*="table__action-cell"]':
        getCellStyles('basic1'),

      '&:nth-of-type(odd):hover [class*="table__action-cell"]':
        getCellStyles('hover'),
    };
  }

  if (isSelectedRow) {
    styles = {
      ...styles,
      backgroundColor: theme?.color.table.bg.selected,
    };
  }

  return styles;
}) as ComponentType<TRowStyledProps>;

export const HeadRowStyled = styled('div')<IHeadRowStyledProps>(
  ({ theme, hasBorders }) => {
    const borderStyle = `1px solid ${theme?.color.dropdown.border}`;

    return {
      backgroundColor: theme?.color.table.bg.basic2,
      textTransform: 'uppercase',
      ...(hasBorders
        ? {
            border: borderStyle,
            borderRight: 'none',
            borderLeft: 'none',
          }
        : {}),
      borderBottom: borderStyle,
    };
  },
) as ComponentType<IHeadRowStyledProps>;

export const ContainerStyled = styled('div')<{ top: number }>(
  ({ theme, top }) => ({
    position: 'relative',
    fontFamily: theme?.font.main,
    minWidth: '100% !important',
    backgroundColor: theme?.color.table.bg.basic2,
    height: top ? `calc(100vh - ${top}px)` : 'auto',
    display: 'flex',
    flexDirection: 'column',
  }),
);

export const ResizerStyled = styled('div')(() => ({
  width: '3px',
  display: 'inline-block',
  height: '100%',
  position: 'absolute',
  right: 0,
  top: 0,
  'z-index': 1,
  touchAction: 'none',
}));

export const HeaderStyled = styled('div')<IHeaderStyledProps>(
  ({ isPinnedHeader, theme, height = 40 }) => ({
    display: 'flex',
    minHeight: height,
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: theme?.color.typo.secondary,
    // todo replace on ResizerStyled when fix https://jira.exante.eu/browse/RUI-262
    [`&:hover div[role="separator"]`]: {
      backgroundColor: theme?.color.table.bg.basic1,
    },
    ...(isPinnedHeader && {
      position: 'sticky',
      zIndex: 1,
      top: 0,
    }),
  }),
);

export const CellTextContainerStyled = styled('div')(() => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: '16px',
  alignItems: 'center',

  '& input': {
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
}));

export const CellTextLabelStyled = styled('div')<ITextLabelStyledProps>(
  ({ justifyContent }) => ({
    display: 'flex',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    justifyContent,
    flexGrow: 1,
  }),
);

export const TableTopPanelStyled = styled('div')(({ theme }) => ({
  fontSize: theme?.size.text.md,
  color: theme?.color.typo.promo,
  backgroundColor: theme?.color.table.bg.basic2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&:not(:empty)': {
    padding: '8px 24px',
  },
}));

export const LineInfoStyled = styled('b')(() => ({
  marginLeft: '5px',
  marginRight: '5px',
}));

export const TotalLineInfoStyled = styled('span')(() => ({
  marginLeft: '5px',
  marginRight: '5px',
}));

export const HeaderButtonsStyled = styled('div')(() => ({
  display: 'flex',

  '> * + *': {
    marginLeft: '8px',
  },
}));

export const InfoBlockStyled = styled('span')(() => ({
  'font-feature-settings': "'zero' on",
}));

export const WithTooltipWrapperStyled = styled('div')(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flexGrow: 1,
}));

export const TableContentStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isNoData', 'noDataHeight']),
})<TTableContentStyledProps>(({ isNoData, noDataHeight }) => {
  const tableHeaderHeight = '72px';
  const defaultContentHeight = '99px';
  const defaultNoDataHeight = '232px';

  const contentNoDataHeight = noDataHeight
    ? `calc(${tableHeaderHeight} + ${noDataHeight})`
    : `calc(${tableHeaderHeight} + ${defaultNoDataHeight})`;

  return {
    minWidth: 'fit-content',
    minHeight: isNoData ? contentNoDataHeight : defaultContentHeight,
  };
});

export const TableNoDataStyled = styled(NoData, {
  shouldForwardProp: blockNonNativeProps(['isLongTable', 'containerWidth']),
})<TTableNoDataStyledProps>(({ isLongTable, containerWidth }) => ({
  width: isLongTable ? '200px' : '100%',
  transform: isLongTable
    ? `translateX(${containerWidth / 2 - 100}px)`
    : undefined,
}));

export const SortIconContainerStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
  height: '100%',
  backgroundColor: theme?.color.table.bg.basic2,
}));

type TSubHeaderStyledProps = {
  children: ReactNode;
  isTopGroupHeader?: boolean;
};

export const SubHeaderStyled = styled('div')<TSubHeaderStyledProps>(
  ({ theme, isTopGroupHeader }) => {
    const borderStyle = `1px solid ${theme?.color.dropdown.border}`;

    return {
      display: 'flex',
      borderTop: isTopGroupHeader ? borderStyle : 'none',
      height: '100%',
    };
  },
) as ComponentType<TSubHeaderStyledProps>;

export const HeaderCellWrapperStyled = styled('div')(
  ({ hasSubColumns }: { hasSubColumns: boolean }) => ({
    padding: `8px ${hasSubColumns ? 24 : 0}px`,
    marginTop: 'auto',
  }),
);
